import { InsightId } from "../insight/insightTypes";
import { CustomInsightOffset } from "../insight/insightUtil";
import { exhaustiveCheck } from "../util/misc";
import {
  GoalInsight,
  InsightName,
  isAdditiveInsightDefinition,
  isConstantInsightDefinition,
  isDeltaInsightDefinition,
  isDifferentialInsightDefinition,
  isDimensionalInsightDefinition,
  isFractionalInsightDefinition,
  isFunctionInsightDefinition,
  isGoalInsight,
  isInsightId,
  isInsightName,
  isLeaseAttributeDefinition,
  isManualReportInsight,
  isProductInsightDefinition,
  isSummedInsightDefinition,
  ManualInsight,
  ReportInsight,
  Resolution,
  Resolved,
  Unresolved,
} from "./reportInsight";

const visitLeaves = (x: {
  insight: ReportInsight<Resolution>;
  accept: (
    insight: InsightId | InsightName | GoalInsight | ManualInsight,
  ) => void;
}): void => {
  const loop = (i: ReportInsight<Resolution>): void => {
    if (isFractionalInsightDefinition(i)) {
      loop(i.numerator);
      loop(i.denominator);
    } else if (isDeltaInsightDefinition(i)) {
      loop(i.delta);
      loop(i.versus);
    } else if (isDifferentialInsightDefinition(i)) {
      loop(i.minuend);
      for (const sub of i.subtrahends) loop(sub);
    } else if (isProductInsightDefinition(i)) {
      loop(i.multiplicand);
      for (const mul of i.multipliers) loop(mul);
    } else if (isAdditiveInsightDefinition(i)) {
      for (const add of i.addends) loop(add);
    } else if (isFunctionInsightDefinition(i)) {
      for (const par of i.parameters) loop(par);
    } else if (isSummedInsightDefinition(i)) {
      x.accept(i.sum);
    } else if (isDimensionalInsightDefinition(i)) {
      x.accept(i.insight);
    } else if (isGoalInsight(i)) {
      x.accept(i);
    } else if (isInsightId(i)) {
      x.accept(i);
    } else if (isInsightName(i)) {
      x.accept(i);
    } else if (isLeaseAttributeDefinition(i)) {
      x.accept(i.insight);
    } else if (isManualReportInsight(i)) {
      x.accept(i);
    } else if (i == null || isConstantInsightDefinition(i)) {
    } else {
      exhaustiveCheck(i);
    }
  };
  loop(x.insight);
};

/** The numeric identifiers of all known insights (and goals) referenced by this report insight. */
export const reportInsightIds = (
  insight: ReportInsight<Resolved>,
): readonly InsightId[] => {
  const ids = new Array<InsightId>();
  visitLeaves({
    insight: insight,
    accept: (i) => {
      if (isInsightId(i)) ids.push(i);
      else if (isManualReportInsight(insight))
        ids.push(insight.id + CustomInsightOffset);
      else if (isGoalInsight(i)) ids.push(i.goal); // we don't actually want the insight but we don't pull the goal without the insight
    },
  });
  return ids;
};

/** The numeric identifiers of all known insights (and goals) referenced by this report insight. */
export const reportManualInsights = (
  insight: ReportInsight<Resolved>,
): readonly ManualInsight[] => {
  const manuals = new Array<ManualInsight>();
  visitLeaves({
    insight: insight,
    accept: (i) => {
      if (isManualReportInsight(insight)) manuals.push(insight);
    },
  });
  return manuals;
};

/** The names of all custom insights (and goals) referenced by this report insight. */
export const reportInsightNames = (
  insight: ReportInsight<Unresolved>,
): readonly InsightName[] => {
  const names = new Array<InsightName>();
  visitLeaves({
    insight: insight,
    accept: (i) => {
      if (isInsightName(i)) names.push(i);
    },
  });
  return names;
};
