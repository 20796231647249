import { ExcelNumberFormats } from "@joyhub-integration/shared";

export const VegaFormats: Record<string, string> = {
  [ExcelNumberFormats.Integer]: ",d",
  [ExcelNumberFormats.DecimalTenths]: ",.1f",
  [ExcelNumberFormats.DecimalHundredths]: ",.2f",
  [ExcelNumberFormats.DeltaInteger]: "+,d",
  [ExcelNumberFormats.Dollars]: "$,d",
  [ExcelNumberFormats.DollarsAccounting]: "$,d",
  [ExcelNumberFormats.DollarsCents]: "$,.2f",
  [ExcelNumberFormats.DollarsCentsAccounting]: "$,.2f",
  [ExcelNumberFormats.DeltaDollars]: "+$,d",
  [ExcelNumberFormats.DeltaDollarsCents]: "+$,.2f",
  [ExcelNumberFormats.PercentTenths]: ".1%",
  [ExcelNumberFormats.PercentHundredths]: ".2%",
  [ExcelNumberFormats.DeltaPercentTenths]: "+.1%",
  [ExcelNumberFormats.PlainInteger]: ",d",
};

export const generateColorByHeader = (header?: string) => {
  if (!header) return "#000";
  let hash = 0;
  for (let i = 0; i < header.length; i++) {
    hash = header.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    if (value < 16) color += "0";
    color += value.toString(16);
  }
  return color;
};
