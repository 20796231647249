import moment from "moment";

export type MortgageRow = {
  period: number;
  mortgageDate: string;
  loanEndDate: string;
  balance: number;
  principal: number;
  interest: number;
  payoff: number;
  totalInterest: number;
  monthly: number;
  loanLefts: number;
  term: number;
  amortization: number;
  capitalizationRate: number;
  interestRate: number;
};

export const getPaymentDate = (loanStartDate: Date, n: number) =>
  moment(loanStartDate).add(n, "months").format("M/D/YYYY");

export const getMortgageByProperty = (property: any) => {
  const values: MortgageRow[] = [];

  const interestOnlyPeriod = property.interest_only_period ?? 0;
  const loanAmount = property.loan_amount ?? 0;
  const interestRate = property.interest_rate ?? 0;
  const term = property.term ?? 0;
  const amortization = 30 - interestOnlyPeriod;
  const loanStartDate = property.origination_date ?? new Date();
  const capitalizationRate = property.capitalization_rate ?? 0;

  let monthlyAfterIntOnly =
    (loanAmount *
      ((interestRate / 12) *
        Math.pow(1 + interestRate / 12, amortization * 12))) /
    (Math.pow(1 + interestRate / 12, amortization * 12) - 1);
  if (isNaN(monthlyAfterIntOnly)) monthlyAfterIntOnly = 0;

  let balance = loanAmount,
    principal = 0,
    interest = 0,
    payoff = 0,
    totalInterest = 0,
    monthly = 0,
    period = 0,
    loanLefts = 0;

  for (let i = 0; i < 12 * term; i++) {
    period++;
    loanLefts = 12 * term - period > 0 ? 12 * term - period : 0;
    interest =
      period === 1 ? 0 : period > term * 12 ? 0 : balance * (interestRate / 12);

    if (period === term * 12) {
      principal = balance;
      monthly = principal + interest;
    } else {
      monthly =
        period > term * 12
          ? 0
          : period <= interestOnlyPeriod * 12
            ? interest
            : monthlyAfterIntOnly;
      principal =
        period > term * 12
          ? 0
          : period <= interestOnlyPeriod * 12
            ? 0
            : monthly - interest;
    }

    balance = period > term * 12 ? 0 : balance - principal;
    payoff = period === term * 12 - 1 ? balance : 0;
    totalInterest += interest;

    values.push({
      period: i,
      mortgageDate: getPaymentDate(loanStartDate, i),
      loanEndDate: getPaymentDate(
        loanStartDate,
        term * 12 - 1 > 0 ? term * 12 - 1 : 0,
      ),
      balance,
      principal,
      interest,
      payoff,
      totalInterest,
      monthly,
      loanLefts,
      interestRate,
      capitalizationRate,
      amortization,
      term,
    });
  }

  return { values, property };
};
