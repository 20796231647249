import {
  ExcelNumberFormats,
  ReportSheetData,
} from "@joyhub-integration/shared";
import {
  CellStyleModule,
  ClientSideRowModelModule,
  DateFilterModule,
  NumberFilterModule,
  RowStyleModule,
  TextFilterModule,
  ValidationModule,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import React from "react";

import { formatValue } from "../../../utils/formatValue";
import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";

interface NewReportProps {
  data: ReportSheetData;
}

const AgGridReport: React.FC<WithAlertModalProps & NewReportProps> = ({
  data,
}) => {
  const convertDataToGridFormat = (data: any) => {
    const columnDefs = data.columns.map((column: any) => {
      const isPercentFormat = (format: string): boolean => {
        if (!format) return false;
        const percentFormats: string[] = [
          ExcelNumberFormats.PercentTenths,
          ExcelNumberFormats.PercentHundredths,
          ExcelNumberFormats.DeltaPercentTenths,
        ];
        return percentFormats.includes(format);
      };

      const isPercentColumn = isPercentFormat(column.format?.numFmt);

      return {
        headerName: column.header,
        field: column.attribute,
        width: column.width * 16,
        sortable: true,
        filter: isPercentColumn ? "agNumberColumnFilter" : true,
        valueFormatter: ({ value }: any) => formatValue(value, column),
        filterParams: isPercentColumn
          ? {
              numberParser: (text: string | null) => {
                if (!text) return null;

                const value = parseFloat(text.replace(/[^0-9.+-]/g, "")) / 100;
                return value;
              },
              numberFormatter: (value: number | null) =>
                formatValue(value, column),
            }
          : undefined,
      };
    });

    const rowData = data.rows.map((row: any) => {
      const rowObject: Record<string, any> = {};
      row.values.forEach((value: any, index: number) => {
        const column = data.columns[index];
        rowObject[column.attribute] = value;
      });
      return rowObject;
    });

    return { columnDefs, rowData };
  };

  const { columnDefs, rowData } = convertDataToGridFormat(data);

  return (
    <div style={{ height: 600, width: "100%" }}>
      <AgGridReact
        modules={[
          ClientSideRowModelModule,
          TextFilterModule,
          NumberFilterModule,
          DateFilterModule,
          ValidationModule,
          CellStyleModule,
          RowStyleModule,
        ]}
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={{
          resizable: true,
          sortable: true,
          filter: true,
          floatingFilter: true,
        }}
      />
    </div>
  );
};

export default withAlertModal(AgGridReport);
