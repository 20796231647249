import { formatValue } from "../../../../utils/formatValue";
import getDynamicFont from "./getDynamicFont";
import { SnapshotElementPropsType } from "./type";

export const TodayInsight = (props: SnapshotElementPropsType) => {
  const { data, xItems, yItems, xAxisIndex } = props;

  const { boxContainer, getCustomDynamicFont } = getDynamicFont();

  const yItem = yItems.length > 0 ? yItems[0] : undefined;
  const xItem = xItems.length > 0 ? xItems[0] : undefined;

  const yValue = yItem && data.rows[xAxisIndex]?.values[yItem.index];
  const xValue = xItem && data.rows[xAxisIndex]?.values[xItem.index];
  const yHeader = yItem && data.columns[yItem.index]?.header;

  return (
    <div
      ref={boxContainer}
      className="w-100 h-100 d-flex flex-col align-items-center justify-content-between overflow-hidden whitespace-nowrap"
    >
      <div style={{ fontSize: getCustomDynamicFont(1), fontWeight: "bold" }}>
        {yHeader || <div style={{ color: "gray" }}>No Title</div>}
      </div>
      <div
        style={{
          fontSize: getCustomDynamicFont(3.5),
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        {yItem && formatValue(yValue, data.columns[yItem.index])}
      </div>
      <div style={{ fontSize: getCustomDynamicFont(0.6), color: "#666666" }}>
        {xItem && formatValue(xValue, data.columns[xItem.index])}
      </div>
    </div>
  );
};
