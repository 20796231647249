import { AdHocInsightEntity, InsightEntity } from "../report/reportInsight";

export const InsightIdentifierRE = /^[a-zA-Z][_a-zA-Z0-9]*$/;

export type InsightDto = Pick<
  InsightEntity,
  "identifier" | "name" | "description" | "definition" | "manual" | "shared"
>;

export type AdHocInsightDto = Pick<
  AdHocInsightEntity,
  "name" | "active" | "rule" | "description"
>;

export class SkippedRow {
  constructor(
    public readonly rowNumber: number,
    public readonly errors: string[],
  ) {}
}

export class IngestInsightDataResult {
  constructor(
    public readonly importedRows: number,
    public readonly skippedRows: SkippedRow[],
    public readonly allRows: number,
    public readonly errors: string[],
  ) {}
}
