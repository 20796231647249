import { faCalendarAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomColumnType } from "@joyhub-integration/shared";
import React, { useEffect, useMemo, useState } from "react";
import DatePicker from "react-date-picker";
import Select from "react-select";
import Creatable from "react-select/creatable";
import { PropertyDropDownType } from "../../../services/propertiesService";
import { createOptionsFromEnum } from "../../../utils/selectOptions";
import { handleIntInputKeyPress } from "./utils/handleInputKeyPress";

type CellEditorProps = {
  colKey: string;
  dataType: CustomColumnType;
  cellValue: string | number;
  textColumnOptions: string[];
  onChange: (value: string | number) => void;
  dropDownType: PropertyDropDownType | null;
  originalValue: string | number;
  sendValue: (value?: string) => void;
};

const CellEditor: React.FC<CellEditorProps> = ({
  colKey,
  sendValue,
  dataType,
  cellValue,
  textColumnOptions,
  onChange,
  dropDownType,
  originalValue,
}) => {
  const [enterPressed, setEnterPressed] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (enterPressed && cellValue !== originalValue) {
      setEnterPressed(false);
      setIsFocused(false);
      sendValue();
    }
  }, [enterPressed, cellValue, originalValue, sendValue]);

  const boolTrueOptionLabel = useMemo(() => {
    if (dataType !== "bool") return undefined;
    return colKey === "is_comparable" ? "Comp" : undefined;
  }, [dataType, colKey]);

  const boolFalseOptionLabel = useMemo(() => {
    if (dataType !== "bool") return undefined;
    return colKey === "is_comparable" ? "Own" : undefined;
  }, [dataType, colKey]);

  switch (dataType) {
    case "year":
    case "number":
    case "dollar":
    case "percentage":
      return (
        <input
          type="number"
          value={cellValue || ""}
          onChange={(evt) => {
            onChange(evt.target.value);
          }}
          onKeyPress={(evt) => {
            if (
              (dataType === "year" ||
                (dataType === "number" && !colKey.startsWith("user_"))) &&
              evt.key === "."
            ) {
              evt.preventDefault();
            }
          }}
          onKeyDown={(evt) => {
            if (dataType === "number") {
              handleIntInputKeyPress(evt);
            }
            if (evt.key === "Enter") {
              setEnterPressed(true);
            }
          }}
        />
      );
    case "text":
      const selectOptions = dropDownType
        ? createOptionsFromEnum(dropDownType)
        : textColumnOptions.map((v) => {
            return {
              label: v,
              value: v,
            };
          });

      const selectProps: Record<string, any> = {
        styles: {
          container: (base: { width: string }) => {
            base.width = "350px";
            return base;
          },
        },
        options: selectOptions,
        isClearable: true,
        isSearchable: dropDownType === null,
        onChange: (evt: { value: any }, action: { action: string }) => {
          if (action.action === "clear") {
            onChange("");
            setIsFocused(false);
          } else if (
            action.action === "select-option" ||
            action.action === "create-option"
          ) {
            onChange(evt?.value ?? "");
            setIsFocused(false);
          }
        },
        defaultValue: {
          label: dropDownType
            ? selectOptions.find((opt) => opt.value === cellValue)?.label
            : cellValue,
          value: cellValue,
        },
        onKeyDown: (evt: { key: string }) => {
          if (dropDownType === null && evt.key === "Enter") {
            setEnterPressed(true);
          }
        },
        onBlur: () => {
          if (isFocused) onChange(originalValue);
          setIsFocused(false);
        },
        onFocus: () => {
          setIsFocused(true);
        },
      };

      const isSelectField = colKey === "state" || colKey === "msa";

      return isSelectField ? (
        <Select {...selectProps} />
      ) : (
        <Creatable {...selectProps} />
      );
    case "bool":
      if (colKey !== "is_comparable") return null;
      const boolOptions = [
        {
          label: boolTrueOptionLabel ?? "true",
          value: "true",
        },
        {
          label: boolFalseOptionLabel ?? "false",
          value: "false",
        },
      ];
      return (
        <Creatable
          styles={{
            container: (base) => {
              base.width = "350px";
              return base;
            },
          }}
          options={boolOptions}
          isClearable={true}
          isSearchable={false}
          onChange={(evt, action) => {
            if (action.action === "clear") {
              onChange("");
              setIsFocused(false);
            } else if (action.action === "select-option") {
              onChange(evt?.value ?? "");
              setIsFocused(false);
            }
          }}
          defaultValue={{
            label: boolOptions.find((opt) => opt.value === cellValue)?.label,
            value: cellValue,
          }}
          onKeyDown={(evt) => {
            if (dropDownType === null && evt.key === "Enter") {
              setEnterPressed(true);
            }
          }}
          onBlur={() => {
            if (isFocused) onChange(originalValue); //revert cell value if user clicks out of the input
            setIsFocused(false);
          }}
          onFocus={() => {
            setIsFocused(true);
          }}
        />
      );
    case "date":
      return (
        <DatePicker
          value={originalValue?.toString() ?? ""}
          onChange={(value) => {
            if (value instanceof Date) {
              const formattedDate = value.toLocaleDateString();
              onChange(formattedDate);
            } else {
              onChange(typeof value === "string" ? value : "");
            }
          }}
          onBlur={() => {
            if (!isFocused) {
              sendValue();
            }
            setIsFocused(false);
          }}
          onFocus={() => setIsFocused(true)}
          clearIcon={null}
          calendarIcon={
            <FontAwesomeIcon icon={faCalendarAlt} className="me-1" />
          }
          className="jh-date-picker btn btn-light"
        />
      );
  }
};

export default CellEditor;
