import { faSlidersH } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  isAdministrator,
  isAllProperties,
  isPropertiesByGroup,
  isPropertiesById,
  isPropertiesByIds,
} from "@joyhub-integration/shared";
import clsx from "clsx";
import { sortBy } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Popover,
} from "reactstrap";

import {
  AllPropertiesLabel,
  getPropertySelectionLabel,
} from "../../services/propertiesService";
import { usePropertiesSelectionQueryParam } from "../../utils/useQueryParams";
import PlatformContext from "../app/PlatformContext";
import ManagePropertyGroupsModal from "./ManagePropertyGroupsModal";
import PropertiesPickerPane from "./PropertiesPickerPane";

const PropertiesPicker: React.FC<{
  className?: string;
  backEnd?: boolean;
  right?: boolean;
  theme?: string;
}> = ({ className, backEnd, right, theme: overrideTheme }) => {
  const {
    embedInfo,
    organization,
    organization_role,
    propertyGroups,
    propertiesMap,
  } = useContext(PlatformContext).platform!;
  const [selection, setSelection] = usePropertiesSelectionQueryParam();
  const [groupMenuOpen, setGroupMenuOpen] = useState(false);
  const [groupModalOpen, setGroupModalOpen] = useState(false);
  const [pickerPopupOpen, setPickerPopupOpen] = useState(false);

  const allProperties = useMemo(
    () => Object.values(propertiesMap).filter((p) => p.front_end),
    [propertiesMap],
  );

  const theme =
    overrideTheme ?? organization.configuration.navbarTheme ?? "dark";
  const lsKey = `selection:${organization.id}`;

  const selectionLabel = useMemo(() => {
    const stored = localStorage.getItem(lsKey);
    const sel =
      selection ?? (stored != null ? JSON.parse(stored) : undefined) ?? {};
    return getPropertySelectionLabel(
      sel,
      allProperties,
      propertyGroups,
      false,
      organization.configuration.customColumns ?? [],
    );
  }, [
    allProperties,
    lsKey,
    organization.configuration.customColumns,
    propertyGroups,
    selection,
  ]);
  const sortedGroups = useMemo(
    () => sortBy(propertyGroups, "name"),
    [propertyGroups],
  );
  const selectedGroup = useMemo(() => {
    const stored = localStorage.getItem(lsKey);
    const sel =
      selection ?? (stored != null ? JSON.parse(stored) : undefined) ?? {};
    return isPropertiesByGroup(sel)
      ? propertyGroups.find((g) => g.id === sel.group)
      : undefined;
  }, [lsKey, propertyGroups, selection]);

  const propertyGroupName = useMemo(() => {
    const stored = localStorage.getItem(lsKey);
    const sel =
      selection ?? (stored != null ? JSON.parse(stored) : undefined) ?? {};
    return isAllProperties(sel) ? AllPropertiesLabel : selectedGroup?.name;
  }, [lsKey, selectedGroup, selection]);

  const embeddedSelection = embedInfo?.configuration?.properties?.selection;

  useEffect(() => {
    if (selection != null) {
      localStorage.setItem(lsKey, JSON.stringify(selection));
    } else {
      const isValidSelection = (sel: any): boolean =>
        sel == null
          ? false
          : isPropertiesById(sel)
            ? allProperties.some((p) => p.id === sel)
            : isPropertiesByIds(sel)
              ? sel.ids.every((id) => allProperties.find((p) => p.id === id))
              : isPropertiesByGroup(sel)
                ? propertyGroups.some((g) => g.id === sel.group)
                : true;

      const stored = localStorage.getItem(lsKey);
      const storedSel = stored != null ? JSON.parse(stored) : undefined;
      const orgSel = { group: organization.configuration.defaultPropertyGroup };
      const defaultSelection = isValidSelection(embeddedSelection ?? storedSel)
        ? (embeddedSelection ?? storedSel)
        : isValidSelection(orgSel)
          ? orgSel
          : {};
      setSelection(defaultSelection, "replaceIn");
    }
  }, [
    allProperties,
    lsKey,
    organization.configuration.defaultPropertyGroup,
    propertyGroups,
    selection,
    setSelection,
    embeddedSelection,
  ]);

  return (
    <div className="flex-row flex-shrink-0 align-items-center">
      <Dropdown
        isOpen={groupMenuOpen}
        toggle={() => setGroupMenuOpen(!groupMenuOpen)}
      >
        <ButtonGroup>
          <DropdownToggle
            id="property-group-picker"
            color={theme}
            caret
            split={propertyGroupName == null}
            style={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
            active={groupMenuOpen}
          >
            {propertyGroupName}
          </DropdownToggle>
          <Button
            id="properties-picker"
            type="button"
            color={theme}
            className={clsx(className, { active: pickerPopupOpen })}
            onClick={(e) => {
              e.stopPropagation();
              setGroupMenuOpen(false);
              setPickerPopupOpen(!pickerPopupOpen);
            }}
            disabled={allProperties.length <= 1}
          >
            <FontAwesomeIcon icon={faSlidersH} />
            {propertyGroupName == null ? (
              <span className="ms-2" title={selectionLabel}>
                {selectionLabel}
              </span>
            ) : null}
          </Button>
          <Popover
            boundariesElement={undefined /*"viewport"*/}
            placement={right ? "bottom-end" : "bottom"}
            isOpen={pickerPopupOpen}
            target="properties-picker"
            toggle={() => setPickerPopupOpen(false)}
          >
            <PropertiesPickerPane
              allProperties={allProperties}
              propertyGroups={propertyGroups}
              backEnd={backEnd}
              toggle={() => setPickerPopupOpen(false)}
            />
          </Popover>
        </ButtonGroup>
        <DropdownMenu end={right}>
          <DropdownItem onClick={() => setSelection({}, "replaceIn")}>
            {AllPropertiesLabel}
          </DropdownItem>
          {sortedGroups.map((group) => (
            <DropdownItem
              key={group.id}
              onClick={() => setSelection({ group: group.id }, "replaceIn")}
            >
              {group.name}
            </DropdownItem>
          ))}
          {isAdministrator(organization_role) && (
            <DropdownItem onClick={() => setGroupModalOpen(true)}>
              Manage groups...
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
      {!groupModalOpen ? null : (
        <ManagePropertyGroupsModal
          propertyGroups={propertyGroups}
          toggle={() => setGroupModalOpen(false)}
        />
      )}
    </div>
  );
};

export default PropertiesPicker;
