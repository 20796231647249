import {
  AgChartsEnterpriseModule,
  AgChartThemeOverrides,
} from "ag-charts-enterprise";
import {
  CellStyleModule,
  ChartModel,
  ChartRef,
  ClientSideRowModelModule,
  DateFilterModule,
  FirstDataRenderedEvent,
  GridReadyEvent,
  IDateFilterParams,
  ITextFilterParams,
  ModuleRegistry,
  NumberFilterModule,
  RowStyle,
  RowStyleModule,
  TextFilterModule,
  ValidationModule,
} from "ag-grid-community";
import {
  ColumnMenuModule,
  ContextMenuModule,
  IntegratedChartsModule,
  MultiFilterModule,
  RowGroupingModule,
  SetFilterModule,
} from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import "./style.css";

let chartModel: ChartModel | undefined;

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  IntegratedChartsModule.with(AgChartsEnterpriseModule),
  ColumnMenuModule,
  ContextMenuModule,
  RowGroupingModule,
  ValidationModule /* Development Only */,
  TextFilterModule,
  NumberFilterModule,
  DateFilterModule, // Import Date Filter Module
  CellStyleModule,
  RowStyleModule,
  MultiFilterModule,
  SetFilterModule,
]);

const filterParams: IDateFilterParams = {
  comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
    const dateAsString = cellValue;
    if (dateAsString == null) return -1;
    const dateParts = dateAsString.split("/");
    const cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0]),
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  },
};

const AgGridDemo = () => {
  const [rowData, setRowData] = useState<any[]>([]);
  const gridRef = useRef<AgGridReact>(null);
  const chartPlaceholderRef = useRef<HTMLDivElement | null>(null);
  const [chartRef, setChartRef] = useState<ChartRef | undefined>();

  const CustomHeader = ({ displayName }: any) => {
    const handleButtonClick = () => {
      alert(`Button clicked in column: ${displayName}`);
    };

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span>{displayName}</span>
        <button onClick={handleButtonClick} style={{ marginLeft: "10px" }}>
          Action
        </button>
      </div>
    );
  };

  const [columnDefs] = useState([
    {
      headerName: "String Value", // Top-level group for strings
      children: [
        {
          headerName: "Plain String",
          field: "stringValue", // Normal string value
          chartDataType: "category",
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset", "apply"],
          } as ITextFilterParams,
          headerComponent: CustomHeader, // Custom header with button
          cellRenderer: ({ value }: any) => (
            <span>
              <a
                href="https://example.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "underline", color: "blue" }}
              >
                {value}
              </a>
            </span>
          ),
        },
      ],
    },
    {
      headerName: "Numeric Formats", // Top-level group
      children: [
        {
          headerName: "Basic Numbers", // Subgroup
          children: [
            {
              headerName: "Integer",
              field: "integer",
              chartDataType: "category",
              filter: "agNumberColumnFilter",
              valueFormatter: ({ value }: any) =>
                new Intl.NumberFormat("en-US", {
                  minimumFractionDigits: 0,
                }).format(value), // "#,##0"
              headerComponent: CustomHeader, // Custom header with button
              aggFunc: "avg",
            },
            {
              headerName: "Decimal Tenths",
              field: "decimalTenths",
              chartDataType: "category",
              filter: "agNumberColumnFilter",
              valueFormatter: ({ value }: any) =>
                new Intl.NumberFormat("en-US", {
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                }).format(value), // "#,##0.0"
              headerComponent: CustomHeader, // Custom header with button
              aggFunc: "avg",
            },
            {
              headerName: "Decimal Hundredths",
              field: "decimalHundredths",
              chartDataType: "excluded",
              filter: "agNumberColumnFilter",
              valueFormatter: ({ value }: any) =>
                new Intl.NumberFormat("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(value), // "#,##0.00"
              cellStyle: ({ value }: any) => {
                // Check if the value is greater than 0
                if (value > 0) {
                  return {
                    fontWeight: "bold",
                    color: "red",
                    fontStyle: "italic",
                    fontSize: "1.5em",
                  }; // Set cell style for positive values
                } else {
                  return {
                    fontWeight: "bold",
                    color: "blue",
                    fontStyle: "italic",
                    fontSize: "1.5em",
                  }; // Set cell style for non-positive values
                }
              },
              aggFunc: "sum",
            },
          ],
        },
        {
          headerName: "Delta Formats", // Subgroup
          children: [
            {
              headerName: "Delta Integer",
              field: "deltaInteger",
              chartDataType: "series",
              filter: "agNumberColumnFilter",
              aggFunc: "sum",
              valueFormatter: ({ value }: any) =>
                value > 0
                  ? `+${value?.toLocaleString()}`
                  : value < 0
                    ? `${value?.toLocaleString()}`
                    : "0", // "+#,##0;-#,##0;0"
            },
          ],
        },
      ],
    },
    {
      headerName: "Currency Formats", // Top-level group
      children: [
        {
          headerName: "Dollars", // Subgroup
          children: [
            {
              headerName: "Dollars",
              field: "dollars",
              chartDataType: "series",
              filter: "agNumberColumnFilter",
              aggFunc: "sum",
              valueFormatter: ({ value }: any) => `$${value?.toLocaleString()}`, // "$#,##0"
            },
            {
              headerName: "Dollars Cents",
              field: "dollarsCents",
              chartDataType: "series",
              filter: "agNumberColumnFilter",
              aggFunc: "sum",
              valueFormatter: ({ value }: any) => `$${value?.toFixed(2)}`, // "$#,##0.00"
            },
          ],
        },
      ],
    },
    {
      headerName: "Other Formats", // Top-level group
      children: [
        {
          headerName: "Percentages", // Subgroup
          children: [
            {
              headerName: "Percent (Hundredths)",
              field: "percentHundredths",
              chartDataType: "series",
              filter: "agMultiColumnFilter",
              aggFunc: "sum",
              valueFormatter: ({ value }: any) =>
                `${(value * 100)?.toFixed(2)}%`, // "0.00%"
            },
          ],
        },
        {
          headerName: "Dates", // Subgroup
          children: [
            {
              headerName: "Date (m/d/yyyy)",
              field: "dateMDYYYY",
              chartDataType: "series",
              filterParams: filterParams,
              filter: "agDateColumnFilter",
              valueFormatter: ({ value }: any) =>
                value ? new Date(value)?.toLocaleDateString("en-US") : "",
            },
          ],
        },
      ],
    },
  ]);

  const handleColumnMoved = (event: any) => {
    console.log("Column Moved:", event);
  };

  const popupParent = useMemo<HTMLElement | null>(() => {
    return document.body;
  }, []);

  const containerStyle = useMemo(() => ({ width: "100%", height: 1000 }), []);
  const gridStyle = useMemo(() => ({ height: 600, width: "100%" }), []);

  const chartThemeOverrides = useMemo<AgChartThemeOverrides>(() => {
    return {
      common: {
        title: {
          enabled: true,
          text: "Medals by Age",
        },
      },
      bar: {
        axes: {
          category: {
            label: {
              rotation: 0,
            },
          },
        },
      },
    };
  }, []);

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.api.createRangeChart({
      chartContainer: document.querySelector("#myChart") as HTMLElement,
      cellRange: {
        columns: [
          "stringValue",
          "deltaInteger",
          "dollars",
          "percentHundredths",
        ],
      },
      seriesChartTypes: [
        { colId: "deltaInteger", chartType: "line", secondaryAxis: true },
        { colId: "dollars", chartType: "line", secondaryAxis: true },
        {
          colId: "percentHundredths",
          chartType: "groupedColumn",
          secondaryAxis: false,
        },
      ],
      chartType: "groupedColumn",
      aggFunc: "sum",
    });
  }, []);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    setRowData([
      {
        stringValue: "Example Text 1",
        integer: 12345,
        decimalTenths: 12345.678,
        decimalHundredths: 12345.678,
        deltaInteger: 12345,
        dollars: 12345,
        dollarsCents: 12345.678,
        percentHundredths: 0.1234,
        dateMDYYYY: "2024-12-12",
      },
      {
        stringValue: "Example Text 2",
        integer: -6789,
        decimalTenths: -6789.45,
        decimalHundredths: -6789.456,
        deltaInteger: -6789,
        dollars: -6789,
        dollarsCents: -6789.45,
        percentHundredths: -0.2345,
        dateMDYYYY: "2023-11-10",
      },
      {
        stringValue: "Example Text 3",
        integer: 0,
        decimalTenths: 0,
        decimalHundredths: 0,
        deltaInteger: 0,
        dollars: 0,
        dollarsCents: 0,
        percentHundredths: 0,
        dateMDYYYY: "2024-01-01",
      },
    ]);
  }, []);

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current!.api.setGridOption(
      "quickFilterText",
      (document.getElementById("filter-text-box") as HTMLInputElement).value,
    );
  }, []);

  const updateChartParams = (chartRef: ChartRef | undefined) => {
    setChartRef((prev) => {
      if (prev != chartRef) {
        // destroy the prev chart if it exists
        prev?.destroyChart();
      }
      return chartRef;
    });
  };

  useEffect(() => {
    if (chartRef) {
      // Append the chart element to the placeholder div
      chartPlaceholderRef.current?.appendChild(chartRef.chartElement);
    }
  }, [chartRef]);

  const saveChart = useCallback(() => {
    const chartModels = gridRef.current!.api.getChartModels() || [];
    if (chartModels.length > 0) {
      chartModel = chartModels[0];
    }
  }, []);

  const restoreChart = useCallback(() => {
    if (!chartModel) return;
    setChartRef(gridRef.current!.api.restoreChart(chartModel)!);
  }, [chartModel]);

  return (
    <div style={containerStyle}>
      <div className="wrapper">
        <div style={gridStyle}>
          <div ref={chartPlaceholderRef} className="chart-wrapper">
            {chartRef ? (
              <div key={chartRef.chartId}>
                <div className="chart-wrapper-top">
                  <h2 className="chart-wrapper-title">
                    Chart created at {new Date().toLocaleString()}
                  </h2>
                  <button onClick={() => updateChartParams(undefined)}>
                    Destroy Chart
                  </button>
                  <button onClick={saveChart}>Save chart</button>
                  <button onClick={restoreChart}>Restore chart</button>
                </div>
              </div>
            ) : (
              <>
                <div>
                  <button onClick={restoreChart}>Restore chart</button>
                </div>
                <div className="chart-placeholder">
                  Chart will be displayed here.
                </div>
              </>
            )}
          </div>
          <div className="example-header">
            <span>Quick Filter:</span>
            <input
              type="text"
              id="filter-text-box"
              placeholder="Filter..."
              className="my-2 ms-2"
              onInput={onFilterTextBoxChanged}
            />
          </div>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={{
              resizable: true,
              sortable: true,
              filter: true,
              floatingFilter: true,
              flex: 1,
            }}
            cellSelection={true}
            onColumnMoved={handleColumnMoved} // Event listener for column moved
            suppressMovableColumns={false}
            popupParent={popupParent}
            enableCharts={true}
            chartThemeOverrides={chartThemeOverrides}
            onFirstDataRendered={onFirstDataRendered}
            createChartContainer={updateChartParams}
            onGridReady={onGridReady}
            getRowStyle={(params) => {
              if (params?.data?.stringValue === "Overall") {
                return {
                  backgroundColor: "#f0f8ff", // Light blue background
                  border: "2px solid #000", // Black border
                  fontWeight: "bold", // Optional for emphasis
                };
              }
              return {} as RowStyle; // Add the type annotation
            }}
            grandTotalRow={"bottom"}
          />
        </div>
      </div>
    </div>
  );
};

export default AgGridDemo;
