import { formatValue } from "../../../../utils/formatValue";
import getDynamicFont from "./getDynamicFont";
import { SnapshotElementPropsType } from "./type";

export const ChangeCard = (props: SnapshotElementPropsType) => {
  const { data, xItems, yItems, xAxisIndex } = props;

  const { boxContainer, getCustomDynamicFont } = getDynamicFont();

  const xItem = xItems.length > 0 ? xItems[0] : undefined;
  const yItemOne = yItems.length > 0 ? yItems[0] : undefined;
  const yItemTwo = yItems.length > 1 ? yItems[1] : undefined;

  const yValueOne = yItemOne && data.rows[xAxisIndex]?.values[yItemOne.index];
  const yValueTwo = yItemTwo && data.rows[xAxisIndex]?.values[yItemTwo.index];
  const xValue = xItem && data.rows[xAxisIndex]?.values[xItem.index];
  const yHeaderOne = yItemOne && data.columns[yItemOne.index]?.header;
  const yHeaderTwo = yItemTwo && data.columns[yItemTwo.index]?.header;

  const formattedValueTwo =
    yItemTwo && formatValue(yValueTwo, data.columns[yItemTwo.index]);
  const isNegative =
    typeof formattedValueTwo === "string" && formattedValueTwo.startsWith("-");
  const isPositive =
    typeof formattedValueTwo === "string" && formattedValueTwo.startsWith("+");

  return (
    <div
      ref={boxContainer}
      className="w-100 h-100 d-flex flex-col align-items-center justify-content-between overflow-hidden whitespace-nowrap"
    >
      <div style={{ fontSize: getCustomDynamicFont(1), fontWeight: "bold" }}>
        {yHeaderOne || <div style={{ color: "gray" }}>No Title</div>}
      </div>
      <div
        className="w-100 d-flex flex-row justify-content-center align-items-center"
        style={{ gap: getCustomDynamicFont(1.5) }}
      >
        <div
          style={{
            fontSize: getCustomDynamicFont(2.5),
            fontWeight: "bold",
          }}
        >
          {yItemOne && formatValue(yValueOne, data.columns[yItemOne.index])}
        </div>
        <div className="d-flex flex-col align-items-center justify-content-center">
          <div
            style={{
              fontSize: getCustomDynamicFont(2),
              fontWeight: "bold",
              color: `${isNegative ? "#b35454" : "#3eb214"}`,
              lineHeight: getCustomDynamicFont(2),
            }}
          >
            {isNegative
              ? formattedValueTwo
              : `${isPositive ? formattedValueTwo : "+" + (formattedValueTwo || "")}`}
          </div>
          <div
            style={{
              fontSize: getCustomDynamicFont(0.6),
              fontWeight: "bold",
            }}
          >
            {yHeaderTwo || <div style={{ color: "gray" }}>No Title</div>}
          </div>
        </div>
      </div>
      <div style={{ fontSize: getCustomDynamicFont(0.6), color: "#666666" }}>
        {xItem && formatValue(xValue, data.columns[xItem.index])}
      </div>
    </div>
  );
};
