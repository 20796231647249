import { orderBy } from "lodash";
import { compare } from "natural-orderby";
import { mapDefined } from "../util/array";
import { ReportDataColumn, ReportDataRow } from "./reportData";
import { SortOrder } from "./reportDefinition";
import { cellDisplayValue, formatStringValue } from "./reportUtil";

export type ReportDirection = 1 | -1;
export type ReportSorting = [number, ReportDirection];

export const reverseDirection = (dir: ReportDirection): ReportDirection =>
  dir === 1 ? -1 : 1;

export const preferredSortDirection = (column: ReportDataColumn) =>
  column.type === "String" ? 1 : -1;

export const baseSortOrder = (columns: ReportDataColumn[]) => {
  const sortOrder = orderBy(
    mapDefined(columns, (column, index) =>
      column.sort != null ? { index, ...column.sort } : undefined,
    ),
    "priority",
  ).map<ReportSorting>(({ index, order }) => [
    index,
    order === "Ascending" ? 1 : -1,
  ]);
  if (columns.length) sortOrder.push([0, preferredSortDirection(columns[0])]);
  return sortOrder;
};

export const newSortOrder = (
  columns: ReportDataColumn[],
  sortOrder: ReportSorting[],
  index: number,
): ReportSorting[] => {
  const dir = preferredSortDirection(columns[index]);
  return [
    [
      index,
      sortOrder[0][0] === index && sortOrder[0][1] === dir
        ? reverseDirection(dir)
        : dir,
    ],
    [index, preferredSortDirection(columns[index])],
  ];
};

const naturallyCompare = compare();

export const naturallyCompareRows = (
  row0: ReportDataRow,
  row1: ReportDataRow,
  index?: number,
) =>
  naturallyCompare(
    formatStringValue(row0.values[index ?? 0]),
    formatStringValue(row1.values[index ?? 0]),
  );

export const rowComparer =
  (sortOrder: ReportSorting[]) =>
  (row0: ReportDataRow, row1: ReportDataRow) => {
    const primarySort = (row0.primarySort ?? 0) - (row1.primarySort ?? 0);
    if (primarySort !== 0) return primarySort;
    for (const [sortColumn, order] of sortOrder) {
      const v0 = cellDisplayValue(row0.values[sortColumn]),
        v1 = cellDisplayValue(row1.values[sortColumn]);
      const cmp =
        v0 == null && typeof v1 === "number"
          ? -1
          : v1 == null && typeof v0 === "number"
            ? 1
            : (typeof v0 === "number" && typeof v1 === "number"
                ? v0 - v1
                : 0) || naturallyCompareRows(row0, row1, sortOrder[0][0]);
      if (cmp !== 0) return cmp * order;
    }
    return 0;
  };

export const reverseSortOrder = (order: SortOrder): SortOrder =>
  order === "Ascending" ? "Descending" : "Ascending";
