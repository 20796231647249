import { CommonFinancialColumn } from "../financials/commonTree";
import { DateIO, PureDate, PureDateIO } from "../util/pureDate";
import { Cause } from "./commonTypes";
import { NewEntity, PkEntity } from "./pk";

export enum FinanceBook {
  Cash,
  Accrual,
  Budget /* These two are not really books, but it helps */,
  ProForma,
}

export const FinanceBooks = [
  FinanceBook.Accrual,
  FinanceBook.Cash,
  FinanceBook.Budget,
  FinanceBook.ProForma,
] as const;

export const isFinanceBook = (a: any): a is FinanceBook =>
  typeof a === "number" && FinanceBooks.includes(a);

export const financeBookNames: Record<FinanceBook, string> = {
  [FinanceBook.Accrual]: "Accrual",
  [FinanceBook.Cash]: "Cash",
  [FinanceBook.Budget]: "Budget",
  [FinanceBook.ProForma]: "Pro Forma",
};

export type FinancialAmounts = Record<string, number>;

export type FinancialMonthlyEntity = PkEntity & {
  system_id: number;
  property_id: number;
  source_property_id: string;
  book: FinanceBook;
  month: PureDate;
  amounts: FinancialAmounts;
  financial_import_id: number;
};

export type NewFinancialMonthlyEntity = NewEntity<FinancialMonthlyEntity>;

export type FinancialImportEntity<Deserialised extends boolean = false> =
  PkEntity & {
    organization_id: number;
    description: string;
    book: FinanceBook;
    tree_code: string | null;
    system_id: number | null;
    path: string;
    filename: string;
    size: number;
    creator_id: number | null;
    import_file_id: number | null;
    created: DateIO<Deserialised>;
  };

export type NewFinancialImportEntity = NewEntity<FinancialImportEntity>;

export const isPersistedFinancialImportEntity = (
  entity: FinancialImportEntity | NewFinancialImportEntity,
): entity is FinancialImportEntity => (entity as any).id != null;

export type FinancialImportDto<Deserialised extends boolean = false> =
  FinancialImportEntity<Deserialised> & {
    creator_name?: string;
  };

export type FinancialImportRequest = {
  tree_code?: string;
  system_id: number;
  book?: FinanceBook;
  description: string;
  key: string;
  filename: string;
  cause: Cause | number;
  import_file_id?: number;
};

export type FinancialImportResponseItem<Deserialised extends boolean = false> =
  {
    property_code: string;
    property_name: string;
    from_date: PureDateIO<Deserialised>;
    to_date: PureDateIO<Deserialised>;
    account_count?: number;
  };

export type FinancialImportResponse<Deserialised extends boolean = false> = {
  description: string;
  tree_code?: string;
  system_id?: number;
  tree_name: string;
  book: FinanceBook;
  imports: Array<FinancialImportResponseItem<Deserialised>>;
  warnings: string[];
};

export type AccountAmount = {
  account_code: string;
  amount: number;
};

export type MonthlyAmounts = {
  month: PureDate;
  amounts: AccountAmount[];
};

export type FinanceImport = {
  property_id: number;
  monthly_amounts: MonthlyAmounts[];
  warnings: string[];
  book?: FinanceBook;
};

export type FinancialsEntity = PkEntity & {
  system_id: number;
  property_id: number;
  source_property_id: string | null;
  month: PureDate;
} & Record<CommonFinancialColumn, number | null>;

export type NewFinancialsEntity = NewEntity<FinancialsEntity>;

export type RawFinancialsEntity = PkEntity & {
  system_id: number;
  property_id: number;
  source_property_id: string | null;
  book: FinanceBook;
  month: PureDate;
  account_number: number | null;
  account_code: string | null;
  account_name: string | null;
  row_num: number;
  parent_row_num: number | null;
  amount: number;
};

export type NewRawFinancialsEntity = NewEntity<RawFinancialsEntity>;
