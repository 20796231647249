import {
  GraphAxis,
  PureDate,
  ReportDataColumn,
  ReportDataRow,
  ReportDataValue,
  ReportSheetData,
  ReportSheetType,
  isComparisonValue,
  isPropertyValue,
} from "@joyhub-integration/shared";
import clsx from "clsx";
import { filter, some } from "lodash";
import { CSSProperties, useContext, useMemo, useState } from "react";
import { PlainObject, VisualizationSpec } from "react-vega";
import Vega from "react-vega/lib/Vega";

import PlatformContext from "../../../app/PlatformContext";
import { VegaFormats, generateColorByHeader } from "./util";
import bar from "./vegaSpecs/bar";
import config from "./vegaSpecs/config";
import line from "./vegaSpecs/line";
import pie from "./vegaSpecs/pie";
import point from "./vegaSpecs/point";

import "./style.css";

const isVsRow = (row: ReportDataRow): boolean => row.key === "vs";

const buildSpec = (
  data: ReportSheetData,
  sheetType: ReportSheetType,
  orgColors?: string[],
) => {
  const getAxisFormat = (axis: GraphAxis) => {
    const numFmt = data.columns.find(
      (column) => column.graphAxis === axis && column.format?.numFmt,
    )?.format?.numFmt;
    return (numFmt && VegaFormats[numFmt]) ?? "";
  };

  const xAxisFormat = getAxisFormat("x");
  const yAxisFormat = getAxisFormat("y");
  const y2AxisFormat = getAxisFormat("y2");
  const y2 = some(data.columns, (column) => column.graphAxis === "y2");
  const y1Columns = filter(data.columns, (column) => column.graphAxis === "y");
  const y2Columns = filter(data.columns, (column) => column.graphAxis === "y2");
  const temporal = sheetType === "Date" && data.columns[0].graphAxis === "x";
  const isPropertyOrDimension =
    (sheetType === "Property" || sheetType === "Dimension") &&
    data.columns[0].graphAxis === "x";
  const isMortgage = sheetType === "Mortgage";
  const isOneRow = data.rows.length === 1;
  const interval = temporal ? data.rowInterval || "Month" : undefined;

  const colors = [...y1Columns, ...y2Columns].map((column, index) => {
    if (!orgColors || orgColors.length <= index)
      return generateColorByHeader(column.header);

    return orgColors[index];
  });

  const getPieColor = (
    item: Record<string, any>,
    index: number,
    property: string,
  ) => {
    if (!orgColors || orgColors.length <= index)
      return generateColorByHeader(JSON.stringify(item[property]));
    return orgColors[index];
  };

  const pieColors = isOneRow
    ? y1Columns.map((column, index) => getPieColor(column, index, "header"))
    : data.rows.map((row, index) => getPieColor(row, index, "key"));

  let spec: VisualizationSpec | undefined;
  switch (data.graphType) {
    case "Line":
      spec = line({
        xAxisFormat,
        yAxisFormat,
        y2AxisFormat,
        y2,
        interval,
        isPropertyOrDimension,
        colors,
      });
      break;
    case "Scatter":
      spec = point({
        xAxisFormat,
        yAxisFormat,
        y2AxisFormat,
        y2,
        interval,
        isPropertyOrDimension,
        colors,
      });
      break;
    case "Pie":
      spec = pie({ xAxisFormat, yAxisFormat, colors: pieColors, isOneRow });
      break;
    case "Bar":
    default:
      const vs = data.rows.some(isVsRow);
      spec = bar({
        xAxisFormat,
        yAxisFormat,
        vsAxisFormat: yAxisFormat,
        y2AxisFormat,
        vs,
        y2,
        interval,
        y1Columns,
        y2Columns,
        isPropertyOrDimension,
        colors,
        isMortgage,
      });
  }

  return spec;
};

const attributeValue = (value: ReportDataValue | undefined): string =>
  isPropertyValue(value) ? value.attribute : "Unknown";

// Not necessarily a property name; could be property group name, etc. These names
// are all put in the property name column.
const propertyName = (
  row: ReportDataRow,
  columns: ReportDataColumn[],
): string => {
  const column = columns.findIndex((col) => col.meta === "property_name");
  return column >= 0 ? attributeValue(row.values[column]) : "Unknown";
};

interface VegaGraphPropsType {
  data: ReportSheetData;
  className?: string;
  style?: CSSProperties;
  actions?: boolean;
}

const VegaGraph = (props: VegaGraphPropsType) => {
  const { data, className, style, actions = false } = props;
  const { type: sheetType, columns, rows } = data;

  const [error, setError] = useState<Error>();
  const { platform } = useContext(PlatformContext);
  const { colors: orgColors } = platform?.organization.configuration!;

  const spec = useMemo(
    () => buildSpec(data, sheetType, orgColors),
    [data, sheetType, orgColors],
  );

  const chartData = useMemo<PlainObject | undefined>(() => {
    const hiddenColumns = columns
      .map((col, idx) => ({ ...col, index: idx }))
      .filter(
        (column) => column?.display === false || column.graphAxis == null,
      );
    const xColumn = columns.findIndex((column) => column.graphAxis === "x");
    const normalRows = rows.filter(
      (row) => row.type === "Normal" && !isVsRow(row),
    );
    const vsRow = rows.find(isVsRow);

    const data = normalRows
      .flatMap((row) =>
        row.values.map((value, i) => {
          const x = row.values[xColumn];

          return columns[i].type === "Number" &&
            !hiddenColumns.find((column) => column.index === i) &&
            columns[i].graphAxis != null &&
            columns[i]?.display !== false
            ? {
                x: isComparisonValue(x)
                  ? x.comparison
                  : isPropertyValue(x)
                    ? attributeValue(x)
                    : sheetType === "Date" && xColumn === 0
                      ? new PureDate(row.key as Date)
                      : x,
                [columns[i].graphAxis ?? "hidden"]: isComparisonValue(value)
                  ? value.comparison
                  : value,
                vs: vsRow?.values[i],
                vsName:
                  vsRow == null
                    ? undefined
                    : propertyName(vsRow, columns).replace("vs ", ""),
                column:
                  columns[i].supercolumn == null
                    ? `${
                        columns[i].bucket == null
                          ? ""
                          : `${columns[i].bucket} - `
                      }${columns[i].header}`
                    : `${columns[i].bucket?.replace("vs ", "")} - ${
                        columns[i].header
                      }`,
              }
            : null;
        }),
      )
      .filter((row) => row !== null);

    return { data };
  }, [columns, rows, sheetType]);

  return chartData == null || spec == null ? null : (
    <>
      <Vega
        className={clsx("w-100 h-100 vega-body", className)}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ...style,
        }}
        spec={spec}
        data={chartData}
        actions={
          actions && {
            export: true,
            editor: platform?.superAdmin,
            compiled: platform?.superAdmin,
            source: false,
          }
        }
        config={config}
        renderer="svg"
        onError={(error: Error) => setError(error)}
        onNewView={() => setError(undefined)}
      />
      {error && <span className="px-4 text-danger">{error.message}</span>}
    </>
  );
};

export default VegaGraph;
